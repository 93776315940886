/**
 * @author  XuHongli
 * @date  2023/1/10 15:43
 * @version 1.0
 * @description
 */
export const columns = [
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: '纳税识别号',
    dataIndex: 'ratepayNo'
  },
  {
    title: '地址',
    dataIndex: 'registAddr',
  },
  {
    title: '开户行',
    dataIndex: 'openBank',
  },
  {
    title: '企业电话',
    dataIndex: 'comPhone'
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: '120px',
    scopedSlots: { customRender: 'action' }
  }
]
