/**
 * @author  XuHongli
 * @date  2023/1/10 15:30
 * @version 1.0
 * @description 发票Api
 */


import request from '@/utils/axios'

/**
 * @description 发票列表
 * @param parameter
 * @return {*}
 */
export function getInvoiceRecodePage(parameter) {
  return request({
    url: '/supplyportal/invoiceRecode/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 申请开票
 * @param id {string | number} 账单ID
 * @return {*}
 */
export function applyInvoice(id) {
  return request({
    url: '/supplyportal/invoiceRecode/apply',
    method: 'post',
    data: { id }
  })
}
/**
 * 获取 发票信息列表
 * @return {*}
 */
export function getInvoiceInfoPage(parameter) {
  return request({
    url: '/supplyportal/invoiceInfo/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 保存/修改 发票信息列表
 * @return {*}
 */
export function updateInvoiceInfo(data) {
  return request({
    url: '/supplyportal/invoiceInfo',
    method: 'post',
    data
  })
}

/**
 * 保存/修改 发票信息列表
 * @param id {string | number}
 * @return {*}
 */
export function delInvoiceInfo(id) {
  return request({
    url: '/supplyportal/invoiceInfo/' + id,
    method: 'delete',
  })
}
