<template>
  <div class="InvoiceInfo bg-white p-10">
    <p class="common-tip-title mb-20 mt-10">发票信息</p>
    <div class="table-page-search-wrapper mb-10">
      <a-button type="primary" size="small" @click="add" v-if="showAdd">新增</a-button>
    </div>
    <s-table
      ref="table"
      :columns="columns"
      :data="loadData"
      :showPagination="'auto'"
      bordered
      rowKey="id"
      size="middle"
    >
      <template slot="action" slot-scope="text, record">
        <a href="javascript:void(0)" class="text-primary" @click="update(record)">编辑</a>
        <a-divider type="vertical" />
        <a href="javascript:void(0)" class="text-danger" @click="del(record.id)">删除</a>

      </template>
    </s-table>

    <SaveInvoiceInfoModal
      :model="mdl"
      v-model="showSaveInvoiceInfoModal"
      @ok="$refs.table.refresh(true)"></SaveInvoiceInfoModal>
  </div>

</template>

<script>
import { delInvoiceInfo, getInvoiceInfoPage } from '@/api/Invoice'
import { columns } from './columns'
import STable from '@/components/Table'
import SaveInvoiceInfoModal from '@/views/User/Invoice/Info/SaveInvoiceInfoModal'

/**
 * @author  XuHongli
 * @date  2023/1/10 15:18
 * @version 1.0
 * @description
 */
export default {
  name: 'InvoiceInfo',
  components: {
    SaveInvoiceInfoModal,
    STable
  },
  data() {
    return {
      showSaveInvoiceInfoModal: false,
      mdl: null,
      columns,
      queryParam: {},
      showAdd:false,
      // 加载数据方法 必须为 Promise 对象,
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
        return getInvoiceInfoPage(requestParameters).then((res) => {
          this.showAdd = res.data.records.length === 0
          return res.data
        })
      }
    }
  },
  methods: {
    add() {
      this.mdl =  {}
      this.showSaveInvoiceInfoModal = true
    },
    update(data){
      this.mdl = data
      this.showSaveInvoiceInfoModal = true
    },
    del(id) {
      delInvoiceInfo(id).then((res) => {
        this.$message.success(res.msg)
        this.$refs.table.refresh(true)
      })
    }
  }
}
</script>

<style scoped>
.ant-table{
  color: #000;
}
</style>
