<template>
  <a-modal
    :centered="true"
    :confirm-loading="confirmLoading"
    :title="model ? '修改发票信息' : '添加发票信息'"
    :visible="visible"
    @cancel="closeModal"
    @ok="handleOk"
  >
    <a-form v-bind="layout">
      <a-form-item label="发票抬头">
        <a-input
          v-model="info.businessName"
          :disabled="true"
          placeholder="发票抬头"
          size="small">
        </a-input>
      </a-form-item>
      <a-form-item label="纳税识别号">
        <a-input
          v-model="info.organizationCode"
          :disabled="true"
          placeholder="纳税识别号"
          size="small">
        </a-input>
      </a-form-item>
    </a-form>
    <a-form :form="form" v-bind="layout">
      <a-form-item label="注册地址">
        <a-input
          v-decorator="['registAddr', {rules:[{required: true, message: '请填写您营业执照上的注册地址'}]}]"
          placeholder="请填写您营业执照上的注册地址"
          size="small">
        </a-input>
      </a-form-item>
      <a-form-item label="开户银行">
        <a-input
          v-decorator="['openBank', {rules:[{required: true, message: '请填写您开户许可证上的开户银行'}]}]"
          placeholder="请填写您开户许可证上的开户银行"
          size="small">
        </a-input>
      </a-form-item>
      <a-form-item label="银行卡号">
        <a-input
          v-decorator="['bankNo', {rules:[{required: true, message: '请填写您开户许可证上的银行卡号'}]}]"
          placeholder="请填写您开户许可证上的银行卡号"
          size="small">
        </a-input>
      </a-form-item>
      <a-form-item label="企业电话">
        <a-input
          v-decorator="['comPhone', {rules:[{required: true, message: '请输入手机号或固话号码'}]}]"
          placeholder="请填写您公司有效的企业电话"
          size="small">
        </a-input>
      </a-form-item>
      <a-form-item label="发票种类">
        <a-radio-group v-decorator="['type', {initialValue:1,rules: [{required: true, message: '请选择发票种类'}]}]">
          <a-radio :value="1">增值税专用发票</a-radio>
          <a-radio :value="2">增值税普通发票</a-radio>
        </a-radio-group>
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script>
import ShowModalMixin from '@/mixin/ShowModalMixin'
import pick from 'lodash.pick'
import { updateInvoiceInfo } from '@/api/Invoice'
import { getUserInfoRz } from '@/api/Login'

/**
 * @author  XuHongli
 * @date  2023/1/10 16:57
 * @version 1.0
 * @description
 */
const fields = ['registAddr', 'openBank', 'bankNo', 'comPhone', 'type', 'id']
export default {
  name: 'SaveInvoiceInfoModal',
  mixins: [ ShowModalMixin ],
  props: {
    model: {
      type: Object,
      default: () => null
    }
  },
  watch: {
    model(n,o){
      if(Object.keys(n).length === 0){
        this.form.resetFields()
      }
    },
  },
  data() {
    return {
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 14 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      info: {}
    }
  },
  created() {
    getUserInfoRz().then(res => {
      this.info = res.data
    })
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.$nextTick(() => {
        fields.forEach(v => this.form.getFieldDecorator(v))
        this.model && this.form.setFieldsValue(pick(this.model, fields))
      })
    })
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if(!err) {
          this.confirmLoading = true
          updateInvoiceInfo(this.model === null?values: Object.assign(this.model,values) ).then((res) => {
            this.confirmLoading = false
            this.$message.success(res.msg)
            this.$emit('ok', true)
            this.closeModal()
          }).catch(() => {
            this.confirmLoading = false
            this.closeModal()
          })
          console.log(values)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
